import { sentry } from '@/plugins/sentry';
import SURVEY_CONSTANTS from '@account/constants/survey-constants';
/**
 * Function to cleanup data after logout
 */
export function logoutCleanup() {
  try {
    window.localStorage.removeItem('bill-alerts');
    window.sessionStorage.removeItem('lastTrusWindowDayModalSeen');
    window.sessionStorage.removeItem(SURVEY_CONSTANTS.SESSION_ID_STORAGE_KEY);
  } catch (error) {
    sentry.captureException(error);
  }
}
