export default {
  SEGMENTS_FORM_SURVEY: 'segmentsFormSurvey',
  DOCUMENTS_AMOUNT_FORM_SURVEY: 'documentsAmountFormSurvey',
  PHONE_FORM_SURVEY: 'phoneFormSurvey',
  SURVEY_QUESTIONS_TOTAL: 3,
  SESSION_ID_STORAGE_KEY: 'unique_additional_data_survey_session_id',
  LOCAL_STORAGE_KEY: 'additional_data_survey',
  TRACKING_SETTINGS: {
    dashboard: {
      showOnFirstView: false,
      uniqueViewBySession: true,
      accessLimit: 2,
      storageKey: 'dashboard',
    },
    documentsDraft: {
      showOnFirstView: true,
      uniqueViewBySession: false,
      accessLimit: 3,
      storageKey: 'documentDraft',
    },
  },
};
